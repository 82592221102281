import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/content/PageTitle"

const Accessibility = () => {
  return (
    <Layout>
      <Seo title="Accessibility - Mitchells & Butlers" description="" />

      <PageTitle title="Accessibility" />

      <div className={`container`}>
        <div className={`w-full mx-auto md:w-3/4 lg:w-1/2 pb-10 md:pb-32`}>
          <p>
            At Mitchells & Butlers we are committed to making our brands and
            services accessible to everyone, including people with disabilities.
            We are working towards making all our websites and apps, including
            this corporate site, as accessible as possible, following Web
            Content Accessibility Guidelines (WCAG). We want to give all users
            the best online experience possible regardless of limitations or
            disabilities.{" "}
          </p>

          <h4>Navigating this website</h4>
          <p>
            If you use the menu on the left-hand side of the page, your screen
            reader will read the menu to you and provide access to the tools on
            the page.{" "}
          </p>

          <h4>Text alternatives</h4>
          <p>
            We have ensured that all key images have text alternatives that
            describe the image being shown.{" "}
          </p>

          <h4>Stylesheets</h4>
          <p>
            The site text has been designed with sufficient contrast however you
            can import and use your own stylesheet if you so wish.{" "}
          </p>

          <h4>Feedback</h4>
          <p>
            If you think there's something we can do to improve the
            accessibility of our website, we'd love to hear from you. Please
            email <a href="mailto:csr@mbplc.com">csr@mbplc.com</a> with any queries or comments.{" "}
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Accessibility
